import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "us.common";
import { History } from "history";
import { courtFeeInvoices } from "reducers/CourtFeeInvoices";

const reducers = {
  ...Common.Reducers,
  courtFeeInvoices,
};

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

export default rootReducer;
