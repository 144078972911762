import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";

import { IInvoiceSearchResult } from "us.common/reducers/MainSearch/Interfaces";
import { IInvoice } from "us.common/components/MainSearch/Interfaces";
import {
  $Avatar,
  $Row,
  $Col,
  $Tag,
  $DateLabel,
  $AmountLabel,
  isViewed,
} from "us.common/components";
import { CaseTypes, DateValues } from "../../Constants";
import { ResultView } from "../ResultView";
import { getRouteUrl } from "us.helper";
import { EyeFilled } from "us.icons";
import { RootState } from "us.helper/types";
import { MainSearchActions } from "us.common/actions";
import { MainCriterias } from "us.common/constants";

/**
 * @description - Columns component for invoice result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
const Invoice: React.FC<IInvoice & PropsFromRedux> = (props) => {
  const {
    invoiceData,
    currentHit,
    totalSearchResult,
    viewedList,
    tabInfo,
    backNavigation,
    location,
    history,
    paginationChangeEvent,
    setNavigation,
    updateViewedList,
  } = props;
  const { t } = useTranslation();

  /**
   * Handle the row click event of invoice result view
   * @param e click event
   * @param exCaseNo invoice view table row caseNo value
   */
  const rowClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    exCaseNo: number,
    key: number
  ) => {
    updateViewedList && updateViewedList([...viewedList, key]);
    e.preventDefault();
    if (window.APP_MODULE == "collection") {
      if (e?.ctrlKey) {
        getRouteUrl.moduleRoute("collection", `/case/${exCaseNo}`);
      } else {
        setNavigation &&
          setNavigation({
            ...backNavigation,
            activeTab: MainCriterias.INVOICE,
            filters: tabInfo?.filterValues,
            canGoBack: true,
            currentHit,
            searchString: location.search,
          });
        history.push(`/case/${exCaseNo}`);
      }
    } else {
      getRouteUrl.moduleRoute("collection", `/case/${exCaseNo}`);
    }
  };

  const columns: ColumnsType<any> = [
    {
      dataIndex: "key",
      key: "key",
      className: "item-type",
      render: (key) => {
        return (
          <div>
            <$Avatar shape="square" size="small">
              {isViewed(viewedList, key) ? <EyeFilled /> : `In`}
            </$Avatar>
          </div>
        );
      },
    },
    {
      dataIndex: "result",
      key: "result",
      render: (subcaseno_row: number, values: IInvoiceSearchResult) => {
        const {
          exCaseNo,
          caseType,
          invoiceNumber,
          debtorNo,
          birthDate,
          address,
          phone,
          mainAmount,
          balance,
          creditorNo,
          creditorName,
          lastMessageActivityName,
          debtorName,
          key,
        } = values ?? {};
        return (
          <div>
            <$Row gutter={16}>
              <$Col span={10}>
                <div className="d-flex align-items-center title">
                  <a onClick={(e: any) => rowClick(e, exCaseNo, key)}>
                    <h3
                      className="mb-0"
                      data-testid="searchResults-invoiceExCaseNo"
                    >
                      {exCaseNo}
                    </h3>
                  </a>
                  {caseType === CaseTypes.OPEN && (
                    <$Tag className="ml-2 tag-status-open">{caseType}</$Tag>
                  )}
                  {caseType === CaseTypes.SENT_TO_COURT && (
                    <$Tag className="ml-2 tag-status-court">{caseType}</$Tag>
                  )}
                  {caseType === CaseTypes.CLOSED && (
                    <$Tag className="ml-2 tag-status-close">{caseType}</$Tag>
                  )}
                  {caseType === CaseTypes.NEXT_DUE_DATE && (
                    <$Tag className="ml-2 tag-status-nextduedate">
                      {caseType}
                    </$Tag>
                  )}
                  {caseType === CaseTypes.SENTENCE_RECEIVED && (
                    <$Tag className="ml-2 tag-status-sentence">{caseType}</$Tag>
                  )}
                  <a onClick={(e: any) => rowClick(e, exCaseNo, key)}>
                    <h3
                      className="mb-0"
                      data-testid="searchResults-invoiceNumberAndName"
                    >
                      {`${t(
                        "US.COLLECTION.COMMON:COMMON.INVOICE_NO"
                      )} : ${invoiceNumber}`}
                    </h3>
                  </a>
                </div>
                <div className="d-flex align-items-start">
                  <div>
                    <label>{`${t(
                      "US.COLLECTION.COMMON:COMMON.DEBTOR"
                    )} :`}</label>
                  </div>
                  <div>
                    <div>
                      <strong className="mr-2">
                        {debtorNo} {debtorName}
                      </strong>
                      <small className="mr-2">
                        {birthDate &&
                          moment
                            .utc(birthDate)
                            .isAfter(moment.utc(DateValues.minDate)) && (
                            <$DateLabel value={birthDate} />
                          )}
                      </small>
                    </div>
                    <div>
                      <small>
                        {[address, phone]
                          .flatMap((value) => (value ? [value] : []))
                          .join(" | ")}
                      </small>
                    </div>
                  </div>
                </div>
              </$Col>
              <$Col span={6}>
                <div className="d-flex align-items-start">
                  <div className="pr-5">
                    <label>
                      {t("US.COLLECTION.COMMON:COMMON.MAIN_AMOUNT")}
                    </label>
                    <h4 className="mb-0">
                      <$AmountLabel value={mainAmount} />
                    </h4>
                  </div>
                  <div>
                    <label>{t("US.COLLECTION.COMMON:COMMON.BALANCE")}</label>
                    <h4 className="mb-0">
                      <$AmountLabel value={balance} />
                    </h4>
                  </div>
                </div>
              </$Col>
              <$Col span={8}>
                <div className="d-flex align-items-start">
                  <label
                    style={{
                      width: 150,
                      minWidth: 150,
                    }}
                    className="d-block pr-2 text-right"
                  >
                    {t("US.COLLECTION.COMMON:COMMON.CREDITOR")} :
                  </label>
                  <div>
                    {creditorNo} {creditorName}
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <label
                    style={{
                      width: 150,
                      minWidth: 150,
                    }}
                    className="d-block pr-2 text-right"
                  >
                    {t("US.COLLECTION.COMMON:COMMON.LAST_MESSAGE_ACTIVITY")} :
                  </label>
                  <div>
                    {lastMessageActivityName && lastMessageActivityName}
                    {!lastMessageActivityName && "---"}
                  </div>
                </div>
              </$Col>
            </$Row>
          </div>
        );
      },
    },
  ];

  return (
    <ResultView
      rowKey="exCaseNo"
      currentHit={currentHit}
      dataSource={invoiceData}
      paginationChangeEvent={paginationChangeEvent}
      tableClassName="result-table"
      tableColumns={columns}
      totalSearchResult={totalSearchResult}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { mainSearch } = state;
  const { backNavigation, viewedList } = mainSearch;

  return {
    backNavigation,
    viewedList,
  };
};

const { all } = MainSearchActions;

const mapDispatchToProps = {
  setNavigation: all.set,
  updateViewedList: all.updateViewedList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Invoice);
