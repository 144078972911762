export enum CourtFeeInvoices {
  UPDATE_DRAWER_SETTINGS = "UPDATE_DRAWER_SETTINGS",
  SEARCH_COURT_FEE_INVOICES = "SEARCH_COURT_FEE_INVOICES",
  SEARCH_COURT_FEE_INVOICES_SUCCESS = "SEARCH_COURT_FEE_INVOICES_SUCCESS",
  SEARCH_COURT_FEE_INVOICES_FAIL = "SEARCH_COURT_FEE_INVOICES_FAIL",

  UPLOAD_COURT_FEE_FILE = "UPLOAD_COURT_FEE_FILE",
  UPLOAD_COURT_FEE_FILE_SUCCESS = "UPLOAD_COURT_FEE_FILE_SUCCESS",
  UPLOAD_COURT_FEE_FILE_FAIL = "UPLOAD_COURT_FEE_FILE_FAIL",
  UPDATE_COURT_FEE_FILE_LIST = "UPDATE_COURT_FEE_FILE_LIST",

  IMPORT_COURT_FEE_FILE = "IMPORT_COURT_FEE_FILE",
  IMPORT_COURT_FEE_FILE_SUCCESS = "IMPORT_COURT_FEE_FILE_SUCCESS",
  IMPORT_COURT_FEE_FILE_FAIL = "IMPORT_COURT_FEE_FILE_FAIL",

  GET_COURT_FEE_INVOICE_DETAILS = "GET_COURT_FEE_INVOICE_DETAILS",
  GET_COURT_FEE_INVOICE_DETAILS_SUCCESS = "GET_COURT_FEE_INVOICE_DETAILS_SUCCESS",
  GET_COURT_FEE_INVOICE_DETAILS_FAIL = "GET_COURT_FEE_INVOICE_DETAILS_FAIL",

  MAP_COURT_FEE_INVOICE_DETAILS = "MAP_COURT_FEE_INVOICE_DETAILS",
  MAP_COURT_FEE_INVOICE_DETAILS_SUCCESS = "MAP_COURT_FEE_INVOICE_DETAILS_SUCCESS",
  MAP_COURT_FEE_INVOICE_DETAILS_FAIL = "MAP_COURT_FEE_INVOICE_DETAILS_FAIL",

  GET_RECENT_COURT_INVOICE_FILES = "GET_RECENT_COURT_INVOICE_FILES",
  GET_RECENT_COURT_INVOICE_FILES_SUCCESS = "GET_RECENT_COURT_INVOICE_FILES_SUCCESS",
  GET_RECENT_COURT_INVOICE_FILES_FAIL = "GET_RECENT_COURT_INVOICE_FILES_FAIL",

  DELETE_COURT_FEE_INVOICE = "DELETE_COURT_FEE_INVOICE",
  DELETE_COURT_FEE_INVOICE_SUCCESS = "DELETE_COURT_FEE_INVOICE_SUCCESS",
  DELETE_COURT_FEE_INVOICE_FAIL = "DELETE_COURT_FEE_INVOICE_FAIL",
}
