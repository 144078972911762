import React from "react";
import { Route } from "react-router-dom";

const Home = React.lazy(() => import("../containers/Home"));
const MainSearch = React.lazy(() => import("../containers/MainSearch"));
const CourtFeeInvoices = React.lazy(
  () => import("../containers/CourtFeeInvoices")
);

export default () => {
  return [
    <Route exact key="home" path={"/"} render={(props: any) => <Home />} />,
    <Route
      exact
      key="court-fee-invoices"
      path={"/court-fee-invoices"}
      render={(props: any) => <CourtFeeInvoices {...props} />}
    />,
    <Route
      exact
      key="search"
      path={"/search"}
      render={(props: any) => <MainSearch {...props} />}
    />,
  ];
};
