import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "services";
import * as Actions from "actions";
import Common from "us.common";
import { CourtFeeInvoices } from "constants/Actions";
import { i18n } from "us.helper";
import { $Message } from "us.common/components";
import { DEFAULT_DRAWER_STATE } from "constants/CourtFeeInvoices";
import _, { isArray } from "lodash";

const { $MessageBox } = Common.Components;
const {
  courtFeeInvoice,
  invoices,
  recentCourtFiles,
  courtFeeDetails,
  courtFeeImportDetails,
  courtFeeFileUpload,
  courtFeeFileImport,
} = Actions.courtFeeInvoices;

const { invoiceList, recentCourtFeeFiles, invoiceFile } = API.courtFeeInvoices;
const {
  SEARCH_COURT_FEE_INVOICES,
  GET_COURT_FEE_INVOICE_DETAILS,
  GET_RECENT_COURT_INVOICE_FILES,
  DELETE_COURT_FEE_INVOICE,
  IMPORT_COURT_FEE_FILE,
  UPLOAD_COURT_FEE_FILE,
  MAP_COURT_FEE_INVOICE_DETAILS,
} = CourtFeeInvoices;

const CourtFeeInvoicesSagas = {
  invoices: {
    search: function* (action: any) {
      try {
        const params = action?.payload.data ?? {};
        const { data, status } = yield call(invoiceList.get, params);
        if (status === 200) {
          yield put(invoices.success(data));
        } else {
          yield put(invoices.success([]));
        }
      } catch (error) {
        yield put(invoices.fail([]));
      }
    },
    delete: function* (action: any) {
      try {
        $Message.loading(i18n.t("COMMON.DELETING"), 0);
        const params = action?.payload.data ?? {};
        const { data, status, invoiceHistory } = yield call(
          invoiceList.delete,
          params
        );
        $Message.destroy();
        if (invoiceHistory && isArray(invoiceHistory)) {
          yield put(invoices.success(invoiceHistory));
        }
        if (status === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.DELETE_PROCESS_STARTED",
            "",
            ""
          );
          yield put(courtFeeInvoice.success(data));
        } else if (
          status === 412 &&
          data.hasOwnProperty("errors") &&
          data.errors[0].code === "412"
        ) {
          $MessageBox(
            "error",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.ONE_OR_MORE_TRANSACTIONS_ARE_REMITTED",
            "",
            ""
          );
          yield put(courtFeeInvoice.fail([]));
        } else if (
          status === 403 &&
          data.hasOwnProperty("errors") &&
          data.errors[0].code === "403"
        ) {
          $MessageBox(
            "error",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.YOU_DO_NOT_HAVE_ACCESS_TO_DELETE",
            "",
            ""
          );
          yield put(courtFeeInvoice.fail([]));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_FEE_INVOICE_DELETE_FAILED",
            "",
            ""
          );
          yield put(courtFeeInvoice.fail([]));
        }
      } catch (error) {
        $Message.destroy();
        $MessageBox(
          "error",
          "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_FEE_INVOICE_DELETE_FAILED",
          "",
          ""
        );
        yield put(courtFeeInvoice.fail([]));
      }
    },
  },
  recentCourtFiles: {
    get: function* (action: any) {
      try {
        const params = action?.payload ?? {};
        const { data, status } = yield call(recentCourtFeeFiles.get, params);
        if (data && status === 200) {
          yield put(recentCourtFiles.success(data));
        } else {
          yield put(recentCourtFiles.success([]));
        }
      } catch (error) {
        yield put(recentCourtFiles.fail([]));
      }
    },
  },
  courtFeeFile: {
    import: function* (action: any) {
      try {
        const params = action?.payload?.data ?? {};
        const { data, status } = yield call(invoiceFile.import, params);
        if (status === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_RECORDS_IMPORT_SUCCESS",
            "",
            ""
          );
          yield put(
            courtFeeFileImport.success(
              data.hasOwnProperty("fileId") ? data : { fileId: 0 }
            )
          );
        } else {
          yield put(courtFeeFileImport.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_RECORDS_IMPORT_FAIL`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_RECORDS_IMPORT_FAIL`,
          "",
          ""
        );
        yield put(courtFeeFileImport.fail([]));
      }
    },
    upload: function* (action: any) {
      try {
        const params = action?.payload?.data ?? {};
        const { data, status } = yield call(invoiceFile.upload, params);
        if (status === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_FILE_UPLOAD_SUCCESS",
            "",
            ""
          );
          yield put(courtFeeFileUpload.success(data));
        } else if (data?.hasOwnProperty("message")) {
          yield put(courtFeeFileUpload.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.CI:COURT_FEE_INVOICES.${data?.message}`,
            "",
            ""
          );
        } else {
          yield put(courtFeeFileUpload.fail([]));
          $MessageBox(
            "error",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_FILE_UPLOAD_FAIL",
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_FILE_UPLOAD_FAIL",
          "",
          ""
        );
        yield put(courtFeeFileUpload.fail([]));
      }
    },
  },
  invoiceDetails: {
    get: function* (action: any) {
      try {
        const params = action?.payload.data ?? {};
        const { data, status } = yield call(
          API.courtFeeInvoices.courtFeeDetails.get,
          params
        );
        if (!_.isEmpty(data) && status === 200) {
          yield put(courtFeeDetails.success(data));
        } else {
          yield put(courtFeeDetails.fail([]));
        }
      } catch (error) {
        yield put(courtFeeDetails.fail([]));
      }
    },
    map: function* (action: any) {
      try {
        const params = action?.payload.data ?? {};

        if (params?.isMap)
          $Message.loading(
            i18n.t("US.COLLECTION.CI:COURT_FEE_INVOICES.RECORDS_MAPPING"),
            0
          );

        const { saveData, searchData, detailsData } = yield call(
          API.courtFeeInvoices.courtFeeDetails.map,
          params
        );
        $Message.destroy();
        if (saveData.status === 200 && saveData?.data?.status == 1) {
          yield put(courtFeeImportDetails.success(saveData.data));
          $MessageBox(
            "success",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_INVOICE_RECORDS_MAP_JOB_HANDED_OVER",
            "",
            ""
          );

          if (invoices.openDrawer && params?.closeDrawer)
            yield put(invoices.openDrawer(DEFAULT_DRAWER_STATE));

          if (!params?.isMap && !params?.closeDrawer) {
            if (detailsData.status === 200) {
              yield put(courtFeeDetails.success(detailsData.data));
            } else {
              yield put(courtFeeDetails.fail([]));
            }
          }

          if (searchData.status === 200) {
            yield put(invoices.success(searchData.data));
          } else {
            yield put(invoices.fail([]));
          }
        } else if (saveData.status === 200 && saveData?.data?.status == 2) {
          yield put(courtFeeImportDetails.success(saveData.data));
          $MessageBox(
            "success",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.COURT_FEE_INVOICE_RECORDS_MAPPED_TO_TRANSACTIONS",
            "",
            ""
          );

          if (invoices.openDrawer && params?.closeDrawer)
            yield put(invoices.openDrawer(DEFAULT_DRAWER_STATE));

          if (!params?.isMap && !params?.closeDrawer) {
            if (detailsData.status === 200) {
              yield put(courtFeeDetails.success(detailsData.data));
            } else {
              yield put(courtFeeDetails.fail([]));
            }
          }

          if (searchData.status === 200) {
            yield put(invoices.success(searchData.data));
          } else {
            yield put(invoices.fail([]));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CI:COURT_FEE_INVOICES.SAVE_INVOICE_RECORDS_FAILED",
            "",
            ""
          );
          yield put(courtFeeImportDetails.fail([]));
        }
      } catch (error) {
        $Message.destroy();
        $MessageBox(
          "error",
          "US.COLLECTION.CI:COURT_FEE_INVOICES.SAVE_INVOICE_RECORDS_FAILED",
          "",
          ""
        );
        yield put(courtFeeImportDetails.fail([]));
      }
    },
  },
};

export default [
  takeLatest(SEARCH_COURT_FEE_INVOICES, CourtFeeInvoicesSagas.invoices.search),
  takeLatest(
    GET_RECENT_COURT_INVOICE_FILES,
    CourtFeeInvoicesSagas.recentCourtFiles.get
  ),
  takeLatest(DELETE_COURT_FEE_INVOICE, CourtFeeInvoicesSagas.invoices.delete),
  takeLatest(IMPORT_COURT_FEE_FILE, CourtFeeInvoicesSagas.courtFeeFile.import),
  takeLatest(UPLOAD_COURT_FEE_FILE, CourtFeeInvoicesSagas.courtFeeFile.upload),
  takeLatest(
    GET_COURT_FEE_INVOICE_DETAILS,
    CourtFeeInvoicesSagas.invoiceDetails.get
  ),
  takeLatest(
    MAP_COURT_FEE_INVOICE_DETAILS,
    CourtFeeInvoicesSagas.invoiceDetails.map
  ),
];
