import { initialState } from "reducers/CourtFeeInvoices/State";
import { CourtFeeInvoices } from "constants/Actions";

const {
  UPDATE_DRAWER_SETTINGS,
  SEARCH_COURT_FEE_INVOICES,
  SEARCH_COURT_FEE_INVOICES_SUCCESS,
  SEARCH_COURT_FEE_INVOICES_FAIL,

  GET_RECENT_COURT_INVOICE_FILES,
  GET_RECENT_COURT_INVOICE_FILES_SUCCESS,
  GET_RECENT_COURT_INVOICE_FILES_FAIL,

  UPDATE_COURT_FEE_FILE_LIST,

  UPLOAD_COURT_FEE_FILE,
  UPLOAD_COURT_FEE_FILE_SUCCESS,
  UPLOAD_COURT_FEE_FILE_FAIL,

  GET_COURT_FEE_INVOICE_DETAILS,
  GET_COURT_FEE_INVOICE_DETAILS_SUCCESS,
  GET_COURT_FEE_INVOICE_DETAILS_FAIL,

  MAP_COURT_FEE_INVOICE_DETAILS,
  MAP_COURT_FEE_INVOICE_DETAILS_SUCCESS,
  MAP_COURT_FEE_INVOICE_DETAILS_FAIL,

  DELETE_COURT_FEE_INVOICE,
  DELETE_COURT_FEE_INVOICE_SUCCESS,
  DELETE_COURT_FEE_INVOICE_FAIL,

  IMPORT_COURT_FEE_FILE,
  IMPORT_COURT_FEE_FILE_SUCCESS,
  IMPORT_COURT_FEE_FILE_FAIL,
} = CourtFeeInvoices;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case UPDATE_DRAWER_SETTINGS:
      return Object.assign({}, state, {
        drawer: payload.data,
        uploadData: {
          data: [],
          isLoading: false,
        },
        fileList: [],
        invoiceImport: {
          data: [],
          isLoading: false,
        },
      });
    case SEARCH_COURT_FEE_INVOICES:
      return Object.assign({}, state, {
        invoices: {
          data: [],
          isLoading: true,
        },
      });
    case SEARCH_COURT_FEE_INVOICES_SUCCESS:
      return Object.assign({}, state, {
        invoices: {
          data: payload.data,
          isLoading: false,
        },
      });
    case SEARCH_COURT_FEE_INVOICES_FAIL:
      return Object.assign({}, state, {
        invoices: {
          data: [],
          isLoading: false,
        },
      });
    case GET_RECENT_COURT_INVOICE_FILES:
      return Object.assign({}, state, {
        recentCourtFiles: {
          data: [],
          isLoading: true,
        },
      });
    case GET_RECENT_COURT_INVOICE_FILES_SUCCESS:
      // console.log("GET_RECENT_COURT_INVOICE_FILES_SUCCESS =< ", payload);
      return Object.assign({}, state, {
        recentCourtFiles: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_RECENT_COURT_INVOICE_FILES_FAIL:
      return Object.assign({}, state, {
        recentCourtFiles: {
          data: [],
          isLoading: false,
        },
      });
    case UPDATE_COURT_FEE_FILE_LIST:
      return Object.assign({}, state, {
        fileList: payload.data,
        uploadData: {
          data: [],
          isLoading: false,
        },
      });
    case UPLOAD_COURT_FEE_FILE:
      return Object.assign({}, state, {
        uploadData: {
          data: [],
          isLoading: true,
        },
      });
    case UPLOAD_COURT_FEE_FILE_SUCCESS:
      return Object.assign({}, state, {
        uploadData: {
          data: payload?.data,
          isLoading: false,
        },
      });
    case UPLOAD_COURT_FEE_FILE_FAIL:
      return Object.assign({}, state, {
        uploadData: {
          data: [],
          isLoading: false,
        },
      });
    case GET_COURT_FEE_INVOICE_DETAILS:
      return Object.assign({}, state, {
        invoiceDetails: {
          ...initialState.invoiceDetails,
          isLoading: true,
        },
      });
    case GET_COURT_FEE_INVOICE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        invoiceDetails: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_COURT_FEE_INVOICE_DETAILS_FAIL:
      return Object.assign({}, state, {
        invoiceDetails: {
          ...state.invoiceDetails,
          isLoading: false,
        },
      });

    case MAP_COURT_FEE_INVOICE_DETAILS:
      return Object.assign({}, state, {
        invoiceDetailsImport: {
          isLoading: true,
        },
        detailsFormValues: {
          ...state.detailsFormValues,
          selectedRecords: payload.data.saveParams?.transactions,
        },
      });
    case MAP_COURT_FEE_INVOICE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        invoiceDetailsImport: {
          isLoading: false,
        },
        detailsFormValues: {
          ...state.detailsFormValues,
          selectedRecords: [],
        },
      });
    case MAP_COURT_FEE_INVOICE_DETAILS_FAIL:
      return Object.assign({}, state, {
        invoiceDetailsImport: {
          isLoading: false,
        },
        detailsFormValues: {
          ...state.detailsFormValues,
          selectedRecords: [],
        },
      });
    case DELETE_COURT_FEE_INVOICE:
      return Object.assign({}, state, {
        invoiceDelete: {
          data: undefined,
          isLoading: true,
        },
      });
    case DELETE_COURT_FEE_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        invoiceDelete: {
          data: payload.data,
          isLoading: false,
        },
      });
    case DELETE_COURT_FEE_INVOICE_FAIL:
      return Object.assign({}, state, {
        invoiceDelete: {
          data: payload.data,
          isLoading: false,
        },
      });
    case IMPORT_COURT_FEE_FILE:
      return Object.assign({}, state, {
        invoiceImport: {
          data: [],
          isLoading: true,
        },
      });
    case IMPORT_COURT_FEE_FILE_SUCCESS:
      return Object.assign({}, state, {
        invoiceImport: {
          data: payload?.data,
          isLoading: false,
        },
      });
    case IMPORT_COURT_FEE_FILE_FAIL:
      return Object.assign({}, state, {
        invoiceImport: {
          data: [],
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
