import {
  DEFAULT_DRAWER_STATE,
  DEFAULT_INVOICE_DETAILS,
} from "constants/CourtFeeInvoices";
import { ICourtFeeInvoices } from "interfaces";

export const initialState: ICourtFeeInvoices = {
  invoices: {
    data: [],
    isLoading: false,
  },
  drawer: DEFAULT_DRAWER_STATE,
  invoiceImport: {
    data: [],
    isLoading: false,
  },
  invoiceDelete: {
    data: [],
    isLoading: false,
  },
  invoiceUpdate: {
    data: [],
    isLoading: false,
  },
  recentCourtFiles: {
    data: [],
    isLoading: false,
  },
  fileList: [],
  uploadData: {
    data: [],
    isLoading: false,
  },
  invoiceDetails: {
    data: DEFAULT_INVOICE_DETAILS,
    isLoading: false,
  },
  detailsFormValues: {
    showMissMatchRecords: false,
    selectedRecords: [],
  },
  invoiceDetailsImport: {
    isLoading: false,
  },
};
