import React from "react";
import { Route, Switch } from "react-router-dom";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import { ConnectedProps, connect } from "react-redux";
import Common from "us.common";
import { ConfigurationProvider } from "us.common/ConfigurationContext";
import commonRoutes from "us.common/routes";
import { SignalRProvider } from "us.common/SignalRProvider";
import { MsalProvider, AuthenticatedTemplate } from "@azure/msal-react";
import { IntialLayout } from "./IntialLayout";
import { courtFeeRoutes } from "routes";
import { RootState } from "us.helper/types";

const { Page404, AppLayout, ConfigProvider } = Common.Components;
interface IAppProps {
  history: History;
  msalInstance: any;
}
const App: React.FC<IAppProps & PropsFromRedux> = ({
  history,
  msalInstance,
  currentLanguage,
}) => {
  const routes = (
    <>
      <MsalProvider instance={msalInstance}>
        <>
          <ConnectedRouter history={history}>
            <IntialLayout />
          </ConnectedRouter>
          <AuthenticatedTemplate>
            <ConnectedRouter history={history}>
              <ConfigurationProvider>
                <SignalRProvider>
                  <AppLayout>
                    <ConfigProvider locale={currentLanguage as any}>
                      <Switch>
                        {[...courtFeeRoutes(), ...commonRoutes]}
                        <Route component={Page404} />
                      </Switch>
                    </ConfigProvider>
                  </AppLayout>
                </SignalRProvider>
              </ConfigurationProvider>
            </ConnectedRouter>
          </AuthenticatedTemplate>
        </>
      </MsalProvider>
    </>
  );
  return <>{routes}</>;
};
const mapStateToProps = (state: RootState) => {
  return {
    currentLanguage: state.common.currentLanguage,
  };
};
const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
