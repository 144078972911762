import {
  CourtFeeInvoiceAction,
  IDrawer,
  CourtFeeInvoiceDetails,
} from "interfaces";

export const DEFAULT_DRAWER_STATE: IDrawer = {
  title: "",
  visible: false,
  type: CourtFeeInvoiceAction.NONE,
  size: "600px",
};

export const DEFAULT_INVOICE_DETAILS: CourtFeeInvoiceDetails = {
  fileId: 0,
  fileName: null,
  fileRecords: 0,
  unmatchedRecords: 0,
  invoiceAmount: 0.0,
  transactions: [],
};

export const DATE_FORMAT_FOR_SERVER = `YYYY-MM-DD HH:mm:ss`;
