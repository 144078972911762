import { IReturnAction } from "us.common/interfaces";

export enum CourtFeeFileStatus {
  "VERIFIED" = "VERIFIED",
  "ERROR" = "ERROR",
  "PARTIALLY_MAPPED" = "PARTIALLY_MAPPED",
  "MAPPED" = "MAPPED",
}

export type CourtFeeInvoice = {
  fileId: number;
  fileName: string;
  fileStatus: string;
  invoiceNo: number;
  uploadedDate: string;
  importedDate: string;
  fileRecords: number;
  invoiceAmount: number;
  importedRecords: number;
  importedAmount: number;
  isValid: boolean;
  hasRemittedItem: boolean;
};

export type CourtFeeInvoiceDetails = {
  fileId: number;
  fileName: null | string;
  fileRecords: number;
  unmatchedRecords: number;
  invoiceAmount: number;
  transactions: Array<ITransaction>;
};

export interface ITransaction {
  recordId: number;
  caseNo: null | string | number;
  amount: number;
  recordStatus: TransactionStatus;
  transactionRegDate: string;
  transactionCode: string;
  transactionAmount: number;
  isMatched: boolean;
}

export enum TransactionStatus {
  CASE_NOT_FOUND = "CASE_NOT_FOUND",
  UNMATCHED_AMOUNT = "UNMATCHED_AMOUNT",
  ALREADY_REGISTERED = "ALREADY_REGISTERED",
  READY_TO_IMPORT = "READY_TO_IMPORT",
  MATCHED = "MAPPED",
  COURT_FEE_NOT_FOUND = "COURTFEE_NOT_FOUND",
}
export interface IDrawer {
  title: string;
  visible: boolean;
  type: CourtFeeInvoiceActionType;
  size?: string | number;
}

export enum CourtFeeInvoiceAction {
  "VIEW" = "view",
  "DELETE" = "delete",
  "IMPORT" = "import",
  "MAP" = "map",
  "NONE" = "none",
}
export type CourtFeeInvoiceActionType =
  | CourtFeeInvoiceAction.VIEW
  | CourtFeeInvoiceAction.IMPORT
  | CourtFeeInvoiceAction.MAP
  | CourtFeeInvoiceAction.DELETE
  | CourtFeeInvoiceAction.NONE;

export interface ICourtFeeInvoices {
  invoices: {
    data: CourtFeeInvoice[];
    isLoading: boolean;
  };
  drawer: IDrawer;
  invoiceImport: {
    data: any;
    isLoading: boolean;
  };
  invoiceDelete: {
    data: any;
    isLoading: boolean;
  };
  invoiceDetails: {
    data: CourtFeeInvoiceDetails;
    isLoading: boolean;
  };
  detailsFormValues: {
    showMissMatchRecords: boolean;
    selectedRecords: Array<number>;
  };
  invoiceDetailsImport: {
    isLoading: boolean;
  };
  invoiceUpdate: {
    data: any;
    isLoading: boolean;
  };
  recentCourtFiles: {
    data: any;
    isLoading: boolean;
  };
  fileList: Array<any>;
  uploadData: {
    data: any;
    isLoading: boolean;
  };
}

export interface ICourtFeeInvoicesAction {
  [key: string]: {
    setFilter?: <T>(data: Array<T> | object) => IReturnAction<T>;
    setSearch?: <T>(data: Array<T> | object) => IReturnAction<T>;
    delete?: <T>(data: Array<T> | object) => IReturnAction<T>;
    updateFiles?: <T>(data: Array<T> | object) => IReturnAction<T>;
  };
}

export interface InvoiceSearchParams {
  searchText?: string;
  startimportdate: string;
  endimportdate: string;
}

export interface InvoiceDeleteParams {
  fileId: number;
  comment: string;
  fileName: string;
}
