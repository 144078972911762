import { CourtFeeInvoices } from "constants/Actions";
import { IAPIAction, ICourtFeeInvoicesAction } from "interfaces";

const {
  UPDATE_DRAWER_SETTINGS,

  SEARCH_COURT_FEE_INVOICES,
  SEARCH_COURT_FEE_INVOICES_SUCCESS,
  SEARCH_COURT_FEE_INVOICES_FAIL,

  UPLOAD_COURT_FEE_FILE,
  UPLOAD_COURT_FEE_FILE_SUCCESS,
  UPLOAD_COURT_FEE_FILE_FAIL,

  GET_RECENT_COURT_INVOICE_FILES,
  GET_RECENT_COURT_INVOICE_FILES_SUCCESS,
  GET_RECENT_COURT_INVOICE_FILES_FAIL,

  IMPORT_COURT_FEE_FILE,
  IMPORT_COURT_FEE_FILE_SUCCESS,
  IMPORT_COURT_FEE_FILE_FAIL,

  GET_COURT_FEE_INVOICE_DETAILS,
  GET_COURT_FEE_INVOICE_DETAILS_SUCCESS,
  GET_COURT_FEE_INVOICE_DETAILS_FAIL,

  MAP_COURT_FEE_INVOICE_DETAILS,
  MAP_COURT_FEE_INVOICE_DETAILS_SUCCESS,
  MAP_COURT_FEE_INVOICE_DETAILS_FAIL,

  DELETE_COURT_FEE_INVOICE,
  DELETE_COURT_FEE_INVOICE_SUCCESS,
  DELETE_COURT_FEE_INVOICE_FAIL,

  UPDATE_COURT_FEE_FILE_LIST,
} = CourtFeeInvoices;

export const courtFeeInvoices: Readonly<IAPIAction & ICourtFeeInvoicesAction> =
  {
    invoices: {
      search: (data) => ({
        type: SEARCH_COURT_FEE_INVOICES,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: SEARCH_COURT_FEE_INVOICES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: SEARCH_COURT_FEE_INVOICES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      openDrawer: (data) => ({
        type: UPDATE_DRAWER_SETTINGS,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    recentCourtFiles: {
      get: (data) => ({
        type: GET_RECENT_COURT_INVOICE_FILES,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: GET_RECENT_COURT_INVOICE_FILES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: GET_RECENT_COURT_INVOICE_FILES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    courtFeeDetails: {
      get: (data) => ({
        type: GET_COURT_FEE_INVOICE_DETAILS,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: GET_COURT_FEE_INVOICE_DETAILS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: GET_COURT_FEE_INVOICE_DETAILS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    courtFeeImportDetails: {
      save: (data) => ({
        type: MAP_COURT_FEE_INVOICE_DETAILS,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: MAP_COURT_FEE_INVOICE_DETAILS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: MAP_COURT_FEE_INVOICE_DETAILS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    courtFeeInvoice: {
      delete: (data) => ({
        type: DELETE_COURT_FEE_INVOICE,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: DELETE_COURT_FEE_INVOICE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: DELETE_COURT_FEE_INVOICE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    courtFeeFileUpload: {
      save: (data) => ({
        type: UPLOAD_COURT_FEE_FILE,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: UPLOAD_COURT_FEE_FILE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: UPLOAD_COURT_FEE_FILE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      updateFiles: (data) => ({
        type: UPDATE_COURT_FEE_FILE_LIST,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    courtFeeFileImport: {
      save: (data) => ({
        type: IMPORT_COURT_FEE_FILE,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: IMPORT_COURT_FEE_FILE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: IMPORT_COURT_FEE_FILE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
  };
