import ServiceConfig from "service.config.js";
import { InvoiceDeleteParams, InvoiceSearchParams } from "interfaces";
import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";

export default {
  invoiceList: {
    get: (params: InvoiceSearchParams): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "courtinvoiceservice",
            "courtinvoice/courtinvoiceimportsummary",
            params
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: ({
      deleteParams,
      searchParams,
    }: {
      deleteParams: InvoiceDeleteParams;
      searchParams: InvoiceSearchParams;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "courtinvoiceservice",
            `courtinvoice`,
            deleteParams.fileId,
            { comment: deleteParams.comment, fileName: deleteParams.fileName },
            ServiceConfig()[`courtinvoiceservice`]["courtinvoice"]
          );

          let invoiceResponse: any = null;
          if (status === 200) {
            invoiceResponse = await httpCollection.get(
              "courtinvoiceservice",
              "courtinvoice/courtinvoiceimportsummary",
              searchParams
            );
          }
          resolve({ data, status, invoiceHistory: invoiceResponse?.data });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  // this is for file upload and import services
  invoiceFile: {
    import: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.post(
            "courtinvoiceservice",
            "courtinvoice/importfile",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    upload: <P extends { file: any; fileType: string }>({
      file,
      fileType,
    }: P): Promise<any> => {
      const formData = new FormData();
      formData.append("fileType", fileType);
      formData.append("file", file[0]);

      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.post(
            "courtinvoiceservice",
            `courtinvoice/invoicefile`,
            formData
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  recentCourtFeeFiles: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "courtinvoiceservice",
            "courtinvoice/recentinvoicefiles/5",
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  courtFeeDetails: {
    get: ({ fileId }: { fileId: string }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "courtinvoiceservice",
            `courtinvoice/filedetails?fileid=${fileId}&withvalidation=true`,
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    map: ({
      searchParams,
      saveParams,
      isMap,
      closeDrawer,
    }: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          let detailsData = { data: {}, status: 200 };
          const saveData = await httpCollection.post(
            "courtinvoiceservice",
            "courtinvoice/importrecords",
            saveParams
          );
          if (!isMap && !closeDrawer) {
            detailsData = await httpCollection.get(
              "courtinvoiceservice",
              `courtinvoice/filedetails?fileid=${saveParams?.fileId}&withvalidation=true`,
              {}
            );
          }
          const searchData = await httpCollection.get(
            "courtinvoiceservice",
            "courtinvoice/courtinvoiceimportsummary",
            searchParams
          );
          resolve({
            saveData,
            detailsData,
            searchData,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
